import Vue from 'vue';
import Vuex from 'vuex';
import { getLocalStorage, setLocalStorage } from '../utils/funs/storage';
import config from '../config';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		showLoading: false,
		showLogo: true,
		referer: getLocalStorage("referer"),
		token: getLocalStorage(config._TOKEN_KEY),
        userinfo: getLocalStorage("userinfo") || {},
	},
	mutations: {
		setReferer(state, referer){
			state.referer = referer;
			setLocalStorage("referer", referer);
		},
		setShowLogo(state, show){
			state.showLogo = show;
		},		
		setToken(state, token) {
			state.token = token;
			setLocalStorage(config._TOKEN_KEY, token);
		},
		setLoading(state, loading){
			state.showLoading = loading
		},
		setUserinfo(state, userinfo) {
			state.userinfo = userinfo;
			setLocalStorage("userinfo", userinfo);
		},
	},
	actions: {

	},
	modules: {

	}
});
