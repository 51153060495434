import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './request'
import 'assets/iconfont.css'

import { Icon, Button, Cell, CellGroup, Checkbox, CheckboxGroup, Field, Toast, Search, Swipe, SwipeItem, Lazyload, Loading, Col, Row, Tabbar, TabbarItem, Grid, GridItem, NavBar, ShareSheet, Dialog, AddressEdit, ContactCard, Card, SubmitBar} from 'vant';
Vue.use(Icon);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Search);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Loading);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(NavBar);
Vue.use(ShareSheet);
Vue.use(Dialog);
Vue.use(AddressEdit);
Vue.use(ContactCard);
Vue.use(Card);
Vue.use(SubmitBar);

Vue.prototype.$http = request;
Vue.config.productionTip = false

new Vue({
  router, store,
  render: h => h(App),
}).$mount('#app')
