export default{
    _TOKEN_KEY: 'token',
    _LANG_KEY: 'lang',
    _MAINTENANCE: false,
    _UIN_BASE64: "MzkwNTY1MzUwNQ==",
    _FOLLOW_URL: "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkwNTY1MzUwNQ==#wechat_redirect",
    _ASSETS_HOST: process.env.NODE_ENV === 'production'
            ? 'https://wsapi.hnshipinhao.shop'
            : 'https://wsapi.hnshipinhao.shop',
    _API_URL: process.env.NODE_ENV === 'production'
            ? 'https://wsapi.hnshipinhao.shop/api/'
            : 'https://wsapi.hnshipinhao.shop/api/',
    _BASE_URL: process.env.NODE_ENV === 'production'
            ? 'https://hnshipinhao.shop/#/'
            : 'http://localhost:8080/#/',
}