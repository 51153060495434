// import router from '../router';
import axios from 'axios';
import { extend } from '../utils/funs/copy';
import { isEmpty } from '../utils/funs/types';
import config from '../config';
import store from '../store';

/*
 * 加载中动画
 */
// let counter = 0;
// let loadinginstace = null;
function showLoading(){
	// counter++;
	// loadinginstace = Vue.prototype.$toast({
    //     duration: 0,
    //     message: 'Loading...',
    //     forbidClick: true,
    //     loadingType: 'spinner',
    // });
    // console.log(url)
    store.commit('setLoading', true)
}
function hideLoading(){
	// counter--;
	// if (counter == 0 && loadinginstace) {
	// 	loadinginstace.clear();
	// }
    // console.log(url)
    store.commit('setLoading', false)

}

export default function call(api, options) {
    if (!api) {
        throw new Error("invalid api");
    }

    /*
     * 默认参数及用户参数合并
     */
    let defaults = {
        // axios需要的参数
        baseURL: config._API_URL,
        url: '',
        method: 'get',
        data: null,
        params: null,
        timeout: '8000',
        headers: {
            'Content-Type': 'application/json'
        },
        // 自定义参数
        hasToken: true,
        retryTimes: 1
    }
    extend(defaults, api, options);

    /*
     * 处理axios需要的参数
     */
    // 给请求加上时间戳
    if (defaults.url.indexOf('?') > -1) {
        defaults.url += "&t=" + new Date().getTime();
    } else {
        defaults.url += "?t=" + new Date().getTime();
    }
    // 请求头携带token
    let _token = store.state.token;
    if (defaults.hasToken && !isEmpty(_token)) {
        defaults.headers.Authorization = "Bearer " + _token;
    }

    /*
     * 请求拦截器
     */
    axios.interceptors.request.use(config => {
        showLoading();
        return config;
    }, err => {
        hideLoading();
        return Promise.reject(err);
    });

    axios.interceptors.response.use(res => {
        hideLoading();
        return res;
    }, err => {
        hideLoading();
        let config = err.config;
        if (!config || !config.retryTimes) return Promise.reject(err);
        const { __retryCount = 0, retryDelay = 500, retryTimes } = config;
        // 在请求对象上设置重试次数
        config.__retryCount = __retryCount;
        // 判断是否超过了重试次数
        if (__retryCount >= retryTimes) {
        return Promise.reject(err);
        }
        // 增加重试次数
        config.__retryCount++;
        // 延时处理
        const delay = new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, retryDelay);
        });
        // 重新发起请求
        return delay.then(function () {
            return axios(config);
        });
    });
      

    /*
     * 响应拦截器
     */
    // axios.interceptors.response.use(res => {
    //     hideLoading();
    //     return res;
    // }, error => {
    //     hideLoading();
    //     return Promise.reject(error);
    // });
    
    return axios(defaults);
}