import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

// 屏蔽双击路由链接报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const Setting = () => import ('../views/Setting');
const Register = () => import ('../views/Register');
const Login = () => import ('../views/Login');
const Agree = () => import ('../views/Agree');
const WeAuth = () => import ('../views/WeAuth');
const Share = () => import ('../views/Share');

const ReceiveInvite = () => import ('../views/ReceiveInvite');
const Team = () => import ('../views/Team');
const Orders = () => import ('../views/Orders');
const Address = () => import ('../views/Address');
const Sph = () => import ('../views/Sph');
const Kyc = () => import ('../views/Kyc');
const Cart = () => import ('../views/Cart');

const Home = () => import ('../views/Home');
const User = () => import ('../views/User');
const Course = () => import ('../views/Course');

const routes = [{
    path: '/',
    name: 'home',
    component: Home
}, {
    path: '/user',
    name: 'user',
    component: User,
}, {
    path: '/course/:type/:id?',
    name: 'course',
    component: Course,
}, {
    path: '/login/:i?',
    name: 'login',
    component: Login,
}, 
{
    path: '/register/:i?',
    name: 'register',
    component: Register,
}, 
{
    path: '/setting',
    name: 'setting',
    component: Setting,
}, {
    path: '/sph',
    name: 'sph',
    component: Sph,
}, {
    path: '/kyc',
    name: 'kyc',
    component: Kyc,
},  {
    path: '/agree/:t?',
    name: 'agree',
    component: Agree,
}, {
    path: '/weauth/:i?',
    name: 'weauth',
    component: WeAuth,
}, {
    path: '/share',
    name: 'share',
    component: Share,
}, {
    path: '/r/:i',
    name: 'receiveInvite',
    component: ReceiveInvite,
}, {
    path: '/orders',
    name: 'orders',
    component: Orders,
}, {
    path: '/team/:id?',
    name: 'team',
    component: Team,
}, {
    path: '/address',
    name: 'address',
    component: Address,
}, {
    path: '/cart',
    name: 'cart',
    component: Cart,
}

];

const router = new VueRouter({
    mode: "hash",
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    // if (to.meta.title) {
    //   document.title = to.meta.title
    // }
    let token = store.state.token;
    let noNeedTokenRoute = ['login','home','user','course','agree','weauth','receiveInvite'];
    if (!token && noNeedTokenRoute.indexOf(to.name)<0) {
        return next({ name: 'login' });
    }
    // if(to.name == 'login'){
    //     if(token && token != ''){
    //         return next({ name: 'home'});
    //     }
    // }
    next();
  })
export default router;