export default {
    login: {
        method: 'post',
        url: '/auth/login',
        hasToken: false
    }, agree: {
        method: 'get',
        url: '/agree',
        hasToken: false
    }, getAddress: {
        method: 'get',
        url: '/user/address',
        hasToken: true
    }, postAddress: {
        method: 'post',
        url: '/user/address',
        hasToken: true
    }, getSph: {
        method: 'get',
        url: '/user/sph',
        hasToken: true
    }, postSph: {
        method: 'post',
        url: '/user/sph',
        hasToken: true
    }, getKyc: {
        method: 'get',
        url: '/user/kyc',
        hasToken: true
    }, postKyc: {
        method: 'post',
        url: '/user/kyc',
        hasToken: true
    }, courses: {
        method: 'post',
        url: '/course/list',
        hasToken: false
    }, course: {
        method: 'post',
        url: '/course/index',
        hasToken: false
    }, sms: {
        method: 'post',
        url: '/sms/get',
        hasToken: false
    }, weauth: {
        method: 'post',
        url: '/wechat/auth',
        hasToken: false
    }, wecode: {
        method: 'get',
        url: '/wechat/code',
        hasToken: false
    }, friendInfo: {
        method: 'post',
        url: '/user/friendInfo',
        hasToken: false
    }, buy: {
        method: 'post',
        url: '/order/buy',
        hasToken: true
    }, orders: {
        method: 'post',
        url: '/order/list',
        hasToken: true
    }, team: {
        method: 'post',
        url: '/user/team',
        hasToken: true
    }, payOrder: {
        method: 'post',
        url: '/wxpay/payorder',
        hasToken: true
    },
    
    userinfo: {
        method: 'post',
        url: '/userinfo',
        hasToken: true
    }, 
    
    
}